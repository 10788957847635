import { canNavigate } from "@/plugins/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
import teacher from './teacher';
import student from './student';

Vue.use(VueRouter);

const getDataStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userType = userData && userData.type ? userData.type : null;

  return { accessToken, userData, userType };
};

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: "/",
    redirect: (to) => {
      const { accessToken, userType } = getDataStorage();

      if (accessToken && userType === "teacher")
        return { name: "teacher.documents" };
      if (accessToken && userType === "new_student" || userType === "pass_student")
        return { name: "student.documents" };

      return { name: "auth-login", query: to.query };
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },

  {
    path: "/user/:token/validation",
    name: "user.validation",
    component: () => import("@/views/public/UserValidation.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },

  // Routers
  ...teacher,
  ...student,

  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const { accessToken, userType } = getDataStorage();
  // const { role = "" } = to.meta;

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!accessToken)
      return next({
        name: "auth-login",
        query: { marketplace: to.query.marketplace },
      });

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  // if (accessToken && !role.includes(userType)) {
  //   next("/");
  // }

  return next();
});

export default router;
