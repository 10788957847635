import axios from '@axios';

const getMe = () => {
  return axios.get('/me')
};

const getMeDetail = () => {
  return axios.get('/me/detail')
};

const updateMeDetail = (data) => {
  return axios.post('/me/detail', data)
};

const getMeDocument = () => {
  return axios.get('/me/document')
};

const updateMeDocument = (data) => {
  return axios.post('/me/document', data)
};

export default {
  getMe,
  getMeDetail,
  updateMeDetail,
  getMeDocument,
  updateMeDocument,
};