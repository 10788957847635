import axios from '@axios';

const login = data => {
  return axios.post('/login', data)
};

const logout = () => {
  return axios.post('/logout')
};


export default {
  login,
  logout,
};