const role = 'student';
const layout = 'student';

const student = [
  {
    path: '/student/upload-documents',
    name: 'student.documents',
    component: () => import('@/views/student/IndexView.vue'),
    meta: {
      role,
      layout,
    },
  }
];


export default student;