const role = 'teacher';
const layout = 'teacher';

const teacher = [
  {
    path: '/teacher/upload-documents',
    name: 'teacher.documents',
    component: () => import('@/views/teacher/IndexView.vue'),
    meta: {
      role,
      layout,
    },
  }
];


export default teacher;