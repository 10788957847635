import api from "@/services";

export default {
  namespaced: true,
  state: {
    userDetail: null,
  },
  mutations: {
    SET_USER_DETAIL: (state, data) => {
      state.userDetail = {...data};
    },
  },
  actions: {
    onSetUserDetail: ({ commit }, data) => {
      commit("SET_USER_DETAIL", data);
    },
    async onGetUserDetail({ commit }) {
      const { data: response } = await api.getMeDetail();
      const { data, success } = response;
      if(success) commit("SET_USER_DETAIL", data);
    },
  },
  getters: {
    userDetail: (state) => state.userDetail,
  },
};
